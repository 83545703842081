<script setup>
import menus from "~/configs/menu";
import SocialMedia from "~/components/AppFooter/SocialMedia.vue";

const props = defineProps({
    customMenu: Array,
    isMoto: {
      type: Boolean,
      default: false
    }
})

const { blendEffect } = useBlend();

const visible = defineModel('visible');
const open = ref(visible.value);

blendEffect(visible, true, true, false, () => {
  if(visible.value) visible.value = false;
});

const hideMenu = () => {
  if(!open.value) return;
  visible.value = false;
}

</script>

<template>
  <div class="🟠 mobile-menu | fixed z-50 bg-white w-64 top-10 bottom-0 pt-32 -right-64 transition-all duration-300"
       :class="!isMoto ? 'md:hidden' : 'xl:hidden',
       {'!right-0': visible}"
       @transitionend="open = visible"
       @touchmove.passive.prevent=""
       v-click-outside="() => hideMenu()"
  >
    <div class="flex flex-col h-full">
      <div class="flex flex-col w-full justify-between grow px-8">
        <NuxtLink v-for="menu in props.customMenu? props.customMenu: menus"
                  :href="menu.path ? menu.path: `/moto/kategoria/${menu.slug}`"
                  class="font-extralight text-lg hover:text-azure cursor-pointer transition-colors"
                  @click="visible = false"
        >
          {{ menu.label ? menu.label : menu.name }}
        </NuxtLink>
        <NuxtLink href="/moto" target="_blank" class="h-7 flex flex-col justify-center" v-if="!isMoto">
          <VectorMoto />
        </NuxtLink>
      </div>
      <div class="bg-gray-100 py-8 mt-8 px-8">
        <SocialMedia :clientPortal="false" noVehisLogos forceColumn />
      </div>
    </div>
  </div>
</template>
